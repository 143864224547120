import { isString } from 'lodash-es';
import type { Subscription } from 'rxjs';

import { LocationStrategy } from '@angular/common';
import type { OnChanges, OnDestroy } from '@angular/core';
import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import type { Event } from '@angular/router';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { attrBoolValue } from '@bp/shared/utilities/core';

import { OutletLinkRelativeToTargetBaseDirective } from './outlet-link-relative-to-target-base.directive';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'a[outletLinkRelativeTo], a[outletLinkRelativeToParent], a[outletLinkRelativeToRootFirstChild], a[outletLinkRelativeToRoot]',
	standalone: false,
})
export class OutletLinkRelativeToTargetWithHrefDirective
	extends OutletLinkRelativeToTargetBaseDirective
	implements OnChanges, OnDestroy {

	@HostBinding('attr.target') @Input() target!: string;

	// The url displayed on the anchor element.
	@HostBinding() href!: string;

	private readonly _updateTargetUrlAndHrefSubscription: Subscription;

	constructor(
		private readonly _locationStrategy: LocationStrategy,
		router: Router,
		route: ActivatedRoute,
	) {
		super(router, route);

		this._updateTargetUrlAndHrefSubscription = this._router.events
			.subscribe((s: Event) => s instanceof NavigationEnd && void this._updateTargetUrlAndHref());
	}

	ngOnChanges(): void {
		this._updateTargetUrlAndHref();
	}

	ngOnDestroy(): void {
		this._updateTargetUrlAndHrefSubscription.unsubscribe();
	}

	@HostListener('click', [ '$event.button', '$event.ctrlKey', '$event.metaKey', '$event.shiftKey' ])
	onClick(button: number, ctrlKey: boolean, metaKey: boolean, shiftKey: boolean): boolean {
		if (button !== 0 || ctrlKey || metaKey || shiftKey)
			return true;

		if (isString(this.target) && this.target !== '_self')
			return true;

		void this._router.navigateByUrl(this._urlTree, {
			skipLocationChange: attrBoolValue(this.skipLocationChange),
			replaceUrl: attrBoolValue(this.replaceUrlOnLocationHistory),
			state: this.state,
		});

		return false;
	}

	private _updateTargetUrlAndHref(): void {
		this.href = this._locationStrategy.prepareExternalUrl(this._router.serializeUrl(this._urlTree));
	}
}
