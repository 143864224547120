import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'safe',
	standalone: false,
})
export class SafePipe implements PipeTransform {
	constructor(private readonly _sanitizer: DomSanitizer) { }

	transform(value: string, type: 'html' | 'resourceUrl' | 'script' | 'style' | 'url'): SafeHtml | SafeResourceUrl | SafeScript | SafeStyle | SafeUrl {
		switch (type) {
			case 'html': return this._sanitizer.bypassSecurityTrustHtml(value);

			case 'style': return this._sanitizer.bypassSecurityTrustStyle(value);

			case 'script': return this._sanitizer.bypassSecurityTrustScript(value);

			case 'url': return this._sanitizer.bypassSecurityTrustUrl(value);

			case 'resourceUrl': return this._sanitizer.bypassSecurityTrustResourceUrl(value);

			default: throw new Error(`Unable to bypass security due to the invalid type: ${ type }`);
		}
	}
}
