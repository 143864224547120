/* eslint-disable max-classes-per-file */
import { firstValueFrom, BehaviorSubject, fromEvent, first, from, concatMap, race } from 'rxjs';
import { isEmpty } from 'lodash-es';

import {
	HostBinding, OnChanges, SimpleChanges, ContentChild, ChangeDetectionStrategy, Component, Input, Directive
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Destroyable } from '@bp/frontend/models/common';
import { subscribeOutsideNgZone } from '@bp/frontend/rxjs';

/**
 * Allows to handle image load error
 */
@Directive({
	selector: 'bp-img-error, [bpImgError]',
	standalone: false,
})
export class ImgErrorDirective { }

@Directive({
	selector: 'bp-img-placeholder, [bpImgPlaceholder]',
	standalone: false,
})
export class ImgPlaceholderDirective { }

@Component({
	selector: 'bp-img',
	templateUrl: './img.component.html',
	styleUrls: [ './img.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class ImgComponent extends Destroyable implements OnChanges {

	@Input() url?: string | null;

	/**
	 * An array of img urls the first successfully loaded is gonna be shown
	 */
	@Input() urls?: string[] | null;

	@Input() size?: number | null = null;

	@Input() alt?: string | null = null;

	@Input({ transform: coerceBooleanProperty })
	withPlaceholder = false;

	@Input({ transform: coerceBooleanProperty })
	hiddenOnFailure = false;

	@Input({ transform: coerceBooleanProperty })
	@HostBinding('class.thumbnail-image')
	thumbnail = false;

	@HostBinding('class.has-placeholder')
	protected get _hasPlaceholder(): boolean {
		return this.thumbnail || this.withPlaceholder;
	}

	@ContentChild(ImgErrorDirective)
	protected _imgErrorDirective?: ImgErrorDirective;

	@ContentChild(ImgPlaceholderDirective)
	protected _imgPlaceholderDirective?: ImgPlaceholderDirective;

	protected _isDownloading$ = new BehaviorSubject(true);

	protected _src$ = new BehaviorSubject<string | null>(null);

	protected _loadingFailed$ = new BehaviorSubject(false);

	ngOnChanges({ url, urls }: Partial<SimpleChanges>): void {
		if (url && this.url)
			void this.__setFirstSuccessfullyLoadedImage([ this.url ]);
		else if (urls && !isEmpty(this.urls))
			void this.__setFirstSuccessfullyLoadedImage(this.urls!);
		else {
			this._src$.next(null);

			this._isDownloading$.next(false);

			this._loadingFailed$.next(true);
		}
	}

	private async __setFirstSuccessfullyLoadedImage(sourceUrls: string[]): Promise<void> {
		sourceUrls = this.__whenUrlHasNoExtensionAddLookupExtensions(sourceUrls);

		this._isDownloading$.next(true);

		const loadedImageUrl = await this.__tryLoadingImagesUntilFirstImageFound(sourceUrls);

		if (loadedImageUrl) {
			this._src$.next(loadedImageUrl);

			this._loadingFailed$.next(false);

			// Wait til the img is rendered then show it to apply smooth animation
			setTimeout(() => void this._isDownloading$.next(false), 100);

		} else {
			this._loadingFailed$.next(true);

			this._isDownloading$.next(false);
		}
	}

	private async __tryLoadingImagesUntilFirstImageFound(sourceUrls: string[]): Promise<string | null> {
		const firstLoadedImageUrl$ = from(sourceUrls)
			.pipe(
				concatMap(async sourceUrl => this.__tryPreloadAndCacheImage(sourceUrl)),
				first(imageUrl => imageUrl !== null, null),
			);

		return firstValueFrom(firstLoadedImageUrl$);
	}

	private async __tryPreloadAndCacheImage(sourceUrl: string): Promise<string | null> {
		const img = new Image();

		img.src = sourceUrl;

		if (img.complete)
			return sourceUrl;

		await firstValueFrom(
			race(
				fromEvent(img, 'load'),
				fromEvent(img, 'error'),
			)
				.pipe(subscribeOutsideNgZone()),
		);

		return img.height === 0 ? null : sourceUrl;
	}

	private __whenUrlHasNoExtensionAddLookupExtensions(urls: string[]): string[] {
		return urls.flatMap(url => {
			const hasExtension = (/(\.[^/\\]+)$/u).test(url);

			return hasExtension || url.startsWith('data:') || url.startsWith('http')
				? [ url ]
				: [ 'svg', 'gif', 'png' ].map(extension => `${ url }.${ extension }`);
		});
	}
}
