import { isEqual, omit, uniq } from 'lodash-es';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, skip, tap } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Output } from '@angular/core';
import type { Params } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';

import { PAGE_SIZE } from '@bp/shared/models/common';

import { FADE } from '@bp/frontend/animations';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { OptionalBehaviorSubject } from '@bp/frontend/rxjs';
import { UrlHelper } from '@bp/frontend/utilities/common';

const defaultPageSizeOptions = [ 10, 24, 50, 100, 250 ];

@Component({
	selector: 'bp-paginator',
	templateUrl: './paginator.component.html',
	styleUrls: [ './paginator.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
	standalone: false,
})
export class PaginatorComponent extends Destroyable {

	@Input() set pageSizeOptions(value: number[]) {
		this._pageSizeOptions = uniq(value).sort((a: number, b: number) => a - b);
	}

	get pageSizeOptions(): number[] {
		return this._pageSizeOptions;
	}

	private _pageSizeOptions = defaultPageSizeOptions;

	@Input() totalLength!: number | null;

	@Input() pageLength?: number | null;

	@Input() scrollTarget?: HTMLElement;

	@Output('page') readonly page$ = new OptionalBehaviorSubject<string | undefined>();

	get page(): string | undefined {
		return this.page$.value;
	}

	set page(value: string | undefined) {
		this.scrollTarget?.scrollIntoView({ behavior: 'smooth' });

		this.page$.next(value);
	}

	readonly pageSize$ = new BehaviorSubject(PAGE_SIZE);

	get pageSize(): number {
		return this.pageSize$.value;
	}

	get offset(): number {
		return (this.currentPage - 1) * this.pageSize;
	}

	readonly currentPage$ = new BehaviorSubject(1);

	get currentPage(): number {
		return this.currentPage$.value;
	}

	set currentPage(value: number) {
		this.currentPage$.next(value);
	}

	readonly progressBack$ = new BehaviorSubject(false);

	get progressBack(): boolean {
		return this.progressBack$.value;
	}

	set progressBack(value: boolean) {
		this.progressBack$.next(value);
	}

	readonly progressNext$ = new BehaviorSubject(false);

	get progressNext(): boolean {
		return this.progressNext$.value;
	}

	set progressNext(value: boolean) {
		this.progressNext$.next(value);
	}

	readonly progress$ = combineLatest([
		this.progressBack$,
		this.progressNext$,
	])
		.pipe(map(([ back, next ]) => back || next));

	constructor(
		private readonly _router: Router,
		private readonly _route: ActivatedRoute,
		public cdr: ChangeDetectorRef,
	) {
		super();

		this._route.params
			.pipe(
				map(({ pageSize }) => Number(pageSize)),
				distinctUntilChanged(),
				filter(v => v !== this.pageSize),
				map(v => Number.isNaN(v) ? PAGE_SIZE : v),
				tap((pageSize: number) => void this._whenNoPageSizeFoundAddToPageSizeOptions(pageSize)),
				takeUntilDestroyed(this),
			)
			.subscribe(this.pageSize$);

		this._route.params
			.pipe(
				map(params => omit(params, 'page')),
				distinctUntilChanged(isEqual),
				takeUntilDestroyed(this),
			)
			.subscribe(() => {
				this.page &&= undefined;

				this.currentPage = 1;
			});

		this.pageSize$
			.pipe(
				skip(1),
				takeUntilDestroyed(this),
			)
			.subscribe(v => void this._navigate({ pageSize: v === PAGE_SIZE ? null : v }));
	}

	getBackPage(): number {
		return this.currentPage - 1;
	}

	getNextPage(): number {
		return this.currentPage + 1;
	}

	onBack = (): void => {
		this.page = this.getBackPage()
			.toString();

		this.currentPage = this.getBackPage();
	};

	onNext = (): void => {
		this.page = this.getNextPage()
			.toString();

		this.currentPage = this.getNextPage();
	};

	hasBack = (): boolean => this.offset >= this.pageSize;

	hasNext = (): boolean => this.offset + (this.pageLength ?? 0) < (this.totalLength ?? 0);

	private _navigate(params: Params): void {
		void this._router.navigate(
			[ UrlHelper.mergeLastPrimaryRouteSnapshotParamsWithSourceParams(this._route, params) ],
			{ relativeTo: this._route },
		);
	}

	private _whenNoPageSizeFoundAddToPageSizeOptions(pageSize: number): void {
		if (!this.pageSizeOptions.includes(pageSize))
			this.pageSizeOptions = [ ...defaultPageSizeOptions, pageSize ];
	}
}
