import { Observable } from 'rxjs';

import { Directive, Input, TemplateRef } from '@angular/core';

import { MediaBreakpointDeviceLiteral } from '../models';

import { IfConditionStructuralBaseDirective } from './if-condition-structural-base.directive';

@Directive({
	selector: '[bpIfLessThan]',
	standalone: false,
})
export class IfLessThanDirective extends IfConditionStructuralBaseDirective {
	@Input()
	set bpIfLessThan(mediaBreakpointDeviceLiteral: MediaBreakpointDeviceLiteral) {
		this._setMediaBreakpointDeviceLiteral(mediaBreakpointDeviceLiteral);
	}

	@Input()
	set bpIfLessThanThen(templateRef: TemplateRef<any>) {
		this._setThenTemplate(templateRef);
	}

	@Input()
	set bpIfLessThanElse(templateRef: TemplateRef<any> | null) {
		this._setElseTemplate(templateRef);
	}

	override onMediaBreakpointConditionChange$(mediaBreakpointDeviceLiteral: MediaBreakpointDeviceLiteral): Observable<boolean> {
		return this._mediaService.lessThan$(mediaBreakpointDeviceLiteral);
	}
}
