import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'bpWrapSubstringWithTag',
	standalone: false,
})
export class WrapSubstringWithTagPipe implements PipeTransform {
	transform(value: string, substringOrRegExpString: string, tag: string): string {
		const [ match ] = value.match(new RegExp(substringOrRegExpString, 'gu')) ?? [];

		return match
			? value.replace(match, `<${ tag }>${ match }</${ tag }>`)
			: value;
	}
}
