import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IErrorMessage, ResponseStatusCode, RESPONSE_STATUS_CODE_MESSAGES } from '@bp/shared/models/core';

@Component({
	selector: 'bp-error-messages',
	templateUrl: './error-messages.component.html',
	styleUrls: [ './error-messages.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class ErrorMessagesComponent {

	@Input('src')
	errors!: IErrorMessage[];

	@Input() showType = true;

	protected _internalServerError = RESPONSE_STATUS_CODE_MESSAGES[ResponseStatusCode.InternalServerError];

	protected _noResponseError = RESPONSE_STATUS_CODE_MESSAGES[ResponseStatusCode.NoResponse];

}
