import { Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[bpStatusBarContainer]',
	standalone: false,
})
export class StatusBarContainerDirective {

	$host = this._host.nativeElement;

	constructor(private readonly _host: ElementRef<HTMLElement>) { }

}
