import type { OnChanges } from '@angular/core';
import { Directive, HostBinding, Input, Optional, Self } from '@angular/core';
import { RouterLink } from '@angular/router';

@Directive({
	selector: 'a[bpTargetBlank]',
	standalone: false,
})
export class TargetBlankDirective implements OnChanges {
	@Input('bpTargetBlank') shouldSet: boolean | '' = true;

	@HostBinding('attr.target') get target() {
		return this.shouldSet ? '_blank' : '_self';
	}

	@HostBinding('attr.rel') get rel() {
		return this.shouldSet ? 'noreferrer' : null;
	}

	constructor(@Optional() @Self() private readonly _routerLink?: RouterLink) { }

	ngOnChanges() {
		this.shouldSet = this.shouldSet === '' || !!this.shouldSet;

		if (this._routerLink)
			this._routerLink.target = this.target;
	}
}
