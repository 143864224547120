
import type { OnDestroy } from '@angular/core';
import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';

import { cancelIdleCallback, requestIdleCallback } from '@bp/shared/utilities/core';

import { ZoneService } from '@bp/frontend/rxjs';

@Directive({
	selector: '[bpRenderWhenIdle]',
	standalone: false,
})
export class RenderWhenIdleStructuralDirective implements OnDestroy {

	private _requestIdleCallbackId!: number;

	constructor(
		private readonly _viewContainerRef: ViewContainerRef,
		private readonly _tplRef: TemplateRef<any>,
		private readonly _zoneService: ZoneService,
	) {
		this._renderComponent();
	}

	ngOnDestroy(): void {
		cancelIdleCallback(this._requestIdleCallbackId);
	}

	private _renderComponent() {
		this._requestIdleCallbackId = requestIdleCallback(
			() => void this._zoneService.runInAngularZone(() => void this._viewContainerRef
				.createEmbeddedView(this._tplRef)
				.detectChanges()),
			{ timeout: 1000 },
		);
	}

}
