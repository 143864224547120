import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[bpStopClickPropagation]',
	standalone: false,
})
export class StopClickPropagationDirective {

	@HostListener('click', [ '$event' ])
	onClick(event: Event): void {
		event.stopPropagation();
	}

}
