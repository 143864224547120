import { Directive, Inject, Input, Optional, Self } from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import type { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { OptionalBehaviorSubject } from '@bp/frontend/rxjs';
import { UrlHelper } from '@bp/frontend/utilities/common';

type FilterControlDirectiveValue = string[] | string | null;

@Directive({
	selector: '[bpFilterControl]',
	exportAs: 'filterControl',
	standalone: false,
})
export class FilterControlDirective extends Destroyable {

	@Input('bpFilterControl') name!: string;

	@Input() isClearable = true;

	private readonly _value$ = new OptionalBehaviorSubject<FilterControlDirectiveValue>();

	value$ = this._value$.asObservable();

	get value(): FilterControlDirectiveValue {
		return this._value$.value ?? null;
	}

	private get _control(): ControlValueAccessor & { multiple?: boolean } | undefined {
		return this._controlValueAccessor?.[0];
	}

	constructor(
		@Inject(NG_VALUE_ACCESSOR)
		@Optional()
		@Self()
		private readonly _controlValueAccessor?: ControlValueAccessor[],
		@Optional()
		@Self()
		private readonly _select?: MatSelect,
	) {
		super();

		this._assertControlIsPresent();

		if (this._select) {
			this._select.selectionChange
				.pipe(takeUntilDestroyed(this))
				.subscribe((v?: MatSelectChange) => void this._emitValue(v?.value));
		} else if (this._control)
			this._control.registerOnChange((v: any) => void this._emitValue(v));
	}

	setValue(value: FilterControlDirectiveValue): void {
		this._value$.next(value);

		if (this._select)
			this._select.writeValue(this._select.multiple && !Array.isArray(value) ? [ value ] : value);
		else if (this._control)
			this._control.writeValue(this._control.multiple && !Array.isArray(value) ? [ value ] : value);
	}

	private _emitValue(value?: unknown): void {
		const nextFilterControlValue = UrlHelper.toRouteParamString(value);

		if (nextFilterControlValue !== UrlHelper.toRouteParamString(this.value))
			this._value$.next(UrlHelper.parseRouteParam(nextFilterControlValue!));
	}

	private _assertControlIsPresent(): void {
		if (!this._control && !this._select)
			throw new Error('FilterControlDirective must be used on a component which implements ControlValuesAccessor interface');
	}

}
