import { get, intersection, isArray, isBoolean, isMap, isString } from 'lodash-es';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { isEmpty, includesIgnoringCase } from '@bp/shared/utilities/core';

@Pipe({
	name: 'shallowFilter',
	standalone: false,
})
export class ShallowFilterPipe implements PipeTransform {
	transform<T>(filteredArray?: T[] | null, filter?: Partial<T> | null): T[] | null {
		if (isEmpty(filter) || isEmpty(filteredArray))
			return filteredArray ?? null;

		const filterPropertyKeys = Object.keys(filter);

		return filteredArray.filter(object => filterPropertyKeys.every(propertyKey => {
			const searchTerm = <Map<object, object[]> | string[] | boolean | string | undefined> get(filter, propertyKey);
			const value = <Map<object, object[]> | string[] | boolean | string | undefined> get(object, propertyKey);

			if (isBoolean(searchTerm) || isBoolean(value))
				return searchTerm === value;

			if (isEmpty(searchTerm))
				return true;

			if (isMap(searchTerm)) {
				for (const [ searchTermKey, searchTermValue ] of searchTerm) {
					if (intersection((<Map<object, object[]>> value).get(searchTermKey), searchTermValue).length > 0)
						return true;
				}
			}

			if (isArray(searchTerm))
				return intersection(<string[]>value, searchTerm).length > 0;

			if (isString(searchTerm))
				return includesIgnoringCase(<string | undefined>value ?? '', searchTerm);

			return value === searchTerm;
		}));
	}
}
