/* eslint-disable max-classes-per-file */
import { isNumber, uniq, without } from 'lodash-es';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
	ChangeDetectionStrategy, Component, ContentChild, Directive, ElementRef, HostListener, Input, ViewChild
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { NumberMaskConfig, TextMaskConfigInput } from '@bp/shared/features/text-mask';
import { bpQueueMicrotask, isEmpty } from '@bp/shared/utilities/core';

import { FADE } from '@bp/frontend/animations';
import { FormFieldControlComponent } from '@bp/frontend/components/core';
import { TextMaskDirective } from '@bp/frontend/features/text-mask';

import { InputComponent } from '../input';

/**
 * Allows the user to customize the label.
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'bp-input-chips-label',
	standalone: false,
})
export class InputChipsLabelDirective { }

/**
 * Allows the user to customize the hint.
 */
@Directive({
	selector: 'bp-input-chips-hint, [bpInputChipsHint]',
	standalone: false,
})
export class InputChipsHintDirective { }

/**
 * Allows the user to add prefix.
 */
@Directive({
	selector: 'bp-input-chips-prefix, [bpInputChipsPrefix]',
	standalone: false,
})
export class InputChipsPrefixDirective { }

@Component({
	selector: 'bp-input-chips',
	templateUrl: './input-chips-control.component.html',
	styleUrls: [ './input-chips-control.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
	host: {
		'(focusout)': 'onTouched()',
	},
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: InputChipsControlComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: InputChipsControlComponent,
			multi: true,
		},
	],
	standalone: false,
})
export class InputChipsControlComponent
	extends FormFieldControlComponent<number[] | string[] | null> {

	@Input() number!: boolean;

	@Input() mask!: TextMaskConfigInput;

	/** User-supplied override of the label element. */
	@ContentChild(InputChipsLabelDirective) customLabel?: InputChipsLabelDirective;

	@ContentChild(InputChipsHintDirective) customHint?: InputChipsHintDirective;

	@ContentChild(InputChipsPrefixDirective) prefix?: InputChipsPrefixDirective;

	@ViewChild(TextMaskDirective) maskDirective?: TextMaskDirective;

	@ViewChild('input', { static: true }) inputRef!: ElementRef<HTMLInputElement>;

	get $input(): HTMLInputElement {
		return this.inputRef.nativeElement;
	}

	separatorKeysCodes: number[] = [ ENTER, COMMA ];

	private readonly __parseClipboardInputRegex = new RegExp(String.raw`[\n,]`, 'ug');

	numberMask = InputComponent.numberMask;

	// #region Implementation of the ControlValueAccessor interface
	override writeValue(value: string[] | null): void {
		bpQueueMicrotask(() => void this.setValue(value, { emitChange: false }));
	}
	// #endregion Implementation of the ControlValueAccessor interface

	protected override _onInternalControlValueChange(_value: number[] | string[] | null): void {

		/*
		 * Intentionally left blank, since we don't want to update the external control on input change
		 * only on a chip submit handled by add function
		 */
	}

	add(value: string): void {
		if (value.trim())
			this.setValue([ ...this.getSelectedChips(), value ]);
	}

	remove(item: number | string): void {
		this.setValue(without(this.getSelectedChips(), item));
	}

	focus(): void {
		this.$input.focus();
	}

	@HostListener('paste', [ '$event' ])
	protected _onPaste(event: ClipboardEvent): void {
		const clipboardData = event.clipboardData?.getData('text/plain');

		if (clipboardData) {
			const chips = clipboardData.split(this.__parseClipboardInputRegex);

			this.setValue([ ...this.getSelectedChips(), ...chips ]);
		}
	}

	override setValue(chips: (number | string)[] | null, options?: { emitChange: boolean }): void {
		this.__resetInput();

		super.setValue(this.__buildComponentValue(chips), options);
	}

	getSelectedChips(): (number | string)[] {
		return this.value ?? [];
	}

	private __buildComponentValue(chips: (number | string)[] | null): number[] | string[] | null {
		const chipsComponentValue = this.maskDirective?.config instanceof NumberMaskConfig
			&& !this.maskDirective.config.allowLeadingZeroes
			&& !isEmpty(chips)
			? chips
				.map(chip => isNumber(chip) ? chip : Number(chip.replace(/\s/ug, '')))
				.filter(chip => !Number.isNaN(chip))
			: chips;

		return isEmpty(chipsComponentValue) ? null : uniq(<any[]> chipsComponentValue);
	}

	private __resetInput(): void {
		this.$input.value = '';

		this._internalControl.setValue(null, { emitEvent: false });
	}

}
