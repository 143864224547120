import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IDescribable } from '@bp/shared/models/core';

import { FADE_IN_SEMI_SLOW } from '@bp/frontend/animations';

@Component({
	selector: 'bp-boxed-items',
	templateUrl: './boxed-items.component.html',
	styleUrls: [ './boxed-items.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN_SEMI_SLOW ],
	standalone: false,
})
export class BoxedItemsComponent {

	@Input() items?: (IDescribable & { displayName?: string })[];

}
