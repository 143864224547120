import type { OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, ElementRef, Host, Input, Renderer2 } from '@angular/core';

import { DescribableEnumeration } from '@bp/shared/models/core';
import type { Enumeration } from '@bp/shared/models/core/enum';
import { attrBoolValue } from '@bp/shared/utilities/core';

import { StatusBarContainerDirective } from './status-bar-container.directive';

type StatusPosition = 'left' | 'top';

@Component({
	selector: 'bp-status-bar',
	templateUrl: './status-bar.component.html',
	styleUrls: [ './status-bar.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class StatusBarComponent implements OnChanges {

	@Input('src') status?: DescribableEnumeration;

	@Input() position!: StatusPosition;

	@Input() inner?: boolean | '';

	private readonly _$host = this._host.nativeElement;

	constructor(
		private readonly _host: ElementRef<HTMLElement>,
		@Host() private readonly _container: StatusBarContainerDirective,
		private readonly _renderer: Renderer2,
	) { }

	ngOnChanges({ position, status, inner }: Partial<SimpleChanges>) {
		if (inner)
			this.inner = attrBoolValue(this.inner);

		position && this._setStatusBarPositionClass(position);

		status && this._setStatusClass(status);
	}

	private _setStatusBarPositionClass(position: SimpleChange) {
		if (position.previousValue)
			this._renderer.removeClass(this._container.$host, this._getStatusBarClass(position.previousValue));

		if (position.currentValue)
			this._renderer.addClass(this._container.$host, this._getStatusBarClass(position.currentValue));
	}

	private _setStatusClass(status: SimpleChange) {
		const $target = this.inner ? this._$host : this._container.$host;

		if (status.previousValue)
			this._renderer.removeClass($target, this._getStatusClass(status.previousValue));

		if (status.currentValue)
			this._renderer.addClass($target, this._getStatusClass(status.currentValue));
	}

	private _getStatusClass(status: Enumeration) {
		return `status-${ status.kebabCase }`;
	}

	private _getStatusBarClass(position: StatusPosition) {
		return `status-bar${ this.inner ? '-inner' : '' }-${ position }`;
	}
}
