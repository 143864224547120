import { camelCase } from 'lodash-es';
import { map } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

import { Directive, Self } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { ISortQueryParams } from '@bp/shared/models/common';

import { UrlHelper } from '@bp/frontend/utilities/common';

@Directive({
	selector: '[bpSort][matSort]',
	standalone: false,
})
export class SortDirective {

	constructor(
		@Self() protected _matSort: MatSort,
		protected readonly _router: Router,
		protected readonly _activatedRoute: ActivatedRoute,
	) {
		void this.__setInitialSortStateFromURL();

		this.__onSortChangeUpdateStateInURL();
	}

	private __onSortChangeUpdateStateInURL(): void {
		this._matSort.sortChange
			.pipe(map(({ active, direction }) => (<ISortQueryParams> {
				sortField: direction
					? active
					: null,
				sortDir: direction,
			})))
			.subscribe(params => void this._router.navigate(
				[ UrlHelper.mergeLastPrimaryRouteSnapshotParamsWithSourceParams(this._activatedRoute, params) ],
				{ relativeTo: this._activatedRoute },
			));
	}

	private async __setInitialSortStateFromURL(): Promise<void> {
		const { sortField, sortDir } = await firstValueFrom<Partial<ISortQueryParams>>(this._activatedRoute.params);

		if (sortField) {
			this._matSort.active = camelCase(sortField);

			this._matSort.direction = sortDir ?? 'desc';
		}
	}
}
