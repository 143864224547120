import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ActionConfirmDialogData } from './action-confirm-dialog-data';
import { ActionConfirmDialogComponent } from './action-confirm-dialog.component';

@Component({
	selector: 'bp-delete-confirm-dialog',
	templateUrl: './action-confirm-dialog.component.html',
	styleUrls: [ './action-confirm-dialog.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class DeleteConfirmDialogComponent extends ActionConfirmDialogComponent<ActionConfirmDialogData> {

	protected override _mergeDefaultOptions(data: ActionConfirmDialogData): ActionConfirmDialogData {
		return super._mergeDefaultOptions({
			color: 'warn',
			descriptionText: `This action cannot be undone. <br /> This will permanently delete the <b>${ data.name }</b> ${ data.type }.`,
			confirmButtonText: 'Delete',
			...data,
		});
	}

}
