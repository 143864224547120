import { startWith } from 'rxjs';

import { Directive, Self, inject } from '@angular/core';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { ViewportOverflownObserverDirective } from '@bp/frontend/directives/viewport-overflow-observer';

import { TooltipShownOnViewportOverflowDirective } from './tooltip-shown-on-viewport-overflow.directive';

@Directive({
	selector: '[bpShowTooltipOnViewportOverflow]',
	standalone: false,
})
export class ShowTooltipOnViewportOverflowDirective extends Destroyable {

	tooltip = inject(TooltipShownOnViewportOverflowDirective, { host: true }).tooltip;

	constructor(@Self() private readonly _viewportOverflownObserver: ViewportOverflownObserverDirective) {
		super();

		this._viewportOverflownObserver
			.hasHostElementOverflownViewport
			.pipe(
				startWith(false),
				takeUntilDestroyed(this),
			)
			.subscribe(
				hasOverflown => hasOverflown ? void this.tooltip.enable() : void this.tooltip.disable(),
			);
	}

}
