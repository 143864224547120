import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
	selector: 'bp-status-badge',
	templateUrl: './status-badge.component.html',
	styleUrls: [ './status-badge.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class StatusBadgeComponent {

	@Input()
	@HostBinding('class.enabled')
	enabled!: boolean | null;

	@Input()
	@HostBinding('class')
	status?: 'warning' | null;

}
