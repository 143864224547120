import { isNumber } from 'lodash-es';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'bpIsNumber',
	standalone: false,
})
export class IsNumberPipe implements PipeTransform {
	transform(value: unknown): boolean {
		return isNumber(value);
	}
}
