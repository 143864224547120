export const FIAT_CURRENCIES_CODES = <const>[
	'USD',
	'EUR',
	'ALL',
	'AFN',
	'DZD',
	'AOA',
	'XCD',
	'ARS',
	'AMD',
	'AWG',
	'AUD',
	'AZN',
	'BSD',
	'BHD',
	'BDT',
	'BBD',
	'BYN',
	'BZD',
	'XOF',
	'BMD',
	'INR',
	'BTN',
	'BOB',
	'BAM',
	'BWP',
	'NOK',
	'BRL',
	'BND',
	'BGN',
	'BIF',
	'CVE',
	'KHR',
	'XAF',
	'CAD',
	'KYD',
	'CLP',
	'CNY',
	'COP',
	'KMF',
	'CDF',
	'NZD',
	'CRC',
	'HRK',
	'CUP',
	'CUC',
	'ANG',
	'CZK',
	'DKK',
	'DJF',
	'DOP',
	'EGP',
	'SVC',
	'ERN',
	'ETB',
	'FKP',
	'FJD',
	'XPF',
	'GMD',
	'GEL',
	'GHS',
	'GIP',
	'GTQ',
	'GBP',
	'GNF',
	'GYD',
	'HTG',
	'HNL',
	'HKD',
	'HUF',
	'ISK',
	'IDR',
	'XDR',
	'IRR',
	'IQD',
	'ILS',
	'JMD',
	'JPY',
	'JOD',
	'KZT',
	'KES',
	'KPW',
	'KRW',
	'KWD',
	'KGS',
	'LAK',
	'LBP',
	'LSL',
	'ZAR',
	'LRD',
	'LYD',
	'CHF',
	'MOP',
	'MKD',
	'MGA',
	'MWK',
	'MYR',
	'MVR',
	'MRU',
	'MUR',
	'MXN',
	'MDL',
	'MNT',
	'MAD',
	'MZN',
	'MMK',
	'NAD',
	'NPR',
	'NIO',
	'NGN',
	'OMR',
	'PKR',
	'PAB',
	'PGK',
	'PYG',
	'PEN',
	'PHP',
	'PLN',
	'QAR',
	'RON',
	'RUB',
	'RWF',
	'SHP',
	'WST',
	'STN',
	'SAR',
	'RSD',
	'SCR',
	'SLL',
	'SGD',
	'XSU',
	'SBD',
	'SOS',
	'SSP',
	'LKR',
	'SDG',
	'SRD',
	'SZL',
	'SEK',
	'SYP',
	'TWD',
	'TJS',
	'TZS',
	'THB',
	'TOP',
	'TTD',
	'TND',
	'TRY',
	'TMT',
	'UGX',
	'UAH',
	'AED',
	'UYU',
	'UZS',
	'VUV',
	'VES',
	'VND',
	'YER',
	'ZMW',
	'ZWL',
	//
	'BOV',
	'CLF',
	'COU',
	'XUA',
	'MXV',
	'CHE',
	'CHW',
	'USN',
	'UYI',
	'UYW',
	'AFA',
	'FIM',
	'ALK',
	'ADP',
	'ESP',
	'FRF',
	'AOK',
	'AON',
	'AOR',
	'ARA',
	'ARP',
	'ARY',
	'RUR',
	'ATS',
	'AYM',
	'AZM',
	'BYB',
	'BYR',
	'BEC',
	'BEF',
	'BEL',
	'BOP',
	'BAD',
	'BRB',
	'BRC',
	'BRE',
	'BRN',
	'BRR',
	'BGJ',
	'BGK',
	'BGL',
	'BUK',
	'HRD',
	'CYP',
	'CSJ',
	'CSK',
	'ECS',
	'ECV',
	'GQE',
	'EEK',
	'XEU',
	'GEK',
	'DDM',
	'DEM',
	'GHC',
	'GHP',
	'GRD',
	'GNE',
	'GNS',
	'GWE',
	'GWP',
	'ITL',
	'ISJ',
	'IEP',
	'ILP',
	'ILR',
	'LAJ',
	'LVL',
	'LVR',
	'LSM',
	'ZAL',
	'LTL',
	'LTT',
	'LUC',
	'LUF',
	'LUL',
	'MGF',
	'MVQ',
	'MLF',
	'MTL',
	'MTP',
	'MRO',
	'MXP',
	'MZE',
	'MZM',
	'NLG',
	'NIC',
	'PEH',
	'PEI',
	'PES',
	'PLZ',
	'PTE',
	'ROK',
	'ROL',
	'STD',
	'CSD',
	'SKK',
	'SIT',
	'RHD',
	'ESA',
	'ESB',
	'SDD',
	'SDP',
	'SRG',
	'CHC',
	'TJR',
	'TPE',
	'TRL',
	'TMM',
	'UGS',
	'UGW',
	'UAK',
	'SUR',
	'USS',
	'UYN',
	'UYP',
	'VEB',
	'VEF',
	'VNC',
	'YDD',
	'YUD',
	'YUM',
	'YUN',
	'ZRN',
	'ZRZ',
	'ZMK',
	'ZWC',
	'ZWD',
	'ZWN',
	'ZWR',
];

export type FiatCurrencyCode = typeof FIAT_CURRENCIES_CODES[ number ];
