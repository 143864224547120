import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { sentenceCase } from '@bp/shared/utilities/core';

@Pipe({
	name: 'sentenceCase',
	standalone: false,
})
export class SentenceCasePipe implements PipeTransform {
	transform(value?: string | null): string {
		return sentenceCase(value ?? '');
	}
}
