import type { OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';

@Component({
	selector: 'bp-svg-icon',
	templateUrl: './svg-icon.component.html',
	styleUrls: [ './svg-icon.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class SvgIconComponent implements OnChanges {

	@Input() name!: string;

	private readonly _$host = this._host.nativeElement;

	constructor(
		private readonly _host: ElementRef<HTMLElement>,
		private readonly _renderer: Renderer2,
	) { }

	ngOnChanges({ name }: Partial<SimpleChanges>): void {
		if (name) {
			this._renderer.removeClass(this._$host, name.previousValue);

			this._renderer.addClass(this._$host, name.currentValue);
		}
	}

}
