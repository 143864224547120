/// <reference path="../../../../../../node_modules/moment-timezone/index.d.ts" />

import moment from 'moment';

import 'moment-timezone/builds/moment-timezone-with-data-10-year-range.js';
import { Countries } from '@bp/shared/models/countries';

import { Timezone } from './timezone';

export class Timezones {

	static list: Timezone[] = Timezones._buildTimezones();

	static guessed: Timezone = new Timezone(moment.tz.guess());

	private static _buildTimezones(): Timezone[] {
		return [
			Timezone.UTC,

			...Countries.listWithWorldwide
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				.flatMap(country => (moment.tz.zonesForCountry(country.code) ?? [])
					.map(zoneName => new Timezone(zoneName)))
				.sort((a, b) => a.utcOffset.localeCompare(b.utcOffset)),
		];
	}

}
