import { isNil } from 'lodash-es';

import { Directive, EmbeddedViewRef, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';

// Inspired by https://habr.com/ru/company/tinkoff/blog/580172/
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[ngFor][ngForOf][ngForIfEmpty],[ngFor][ngForOf][ngForIfEmptyOrNil]',
	standalone: false,
})
export class IfEmptyNgForDirective implements OnChanges {

	@Input()
	ngForOf: any[] | null = [];

	@Input()
	ngForIfEmpty?: TemplateRef<unknown>;

	@Input()
	ngForIfEmptyOrNil?: TemplateRef<unknown>;

	private _emptyViewRef?: EmbeddedViewRef<unknown>;

	constructor(private readonly _viewContainerRef: ViewContainerRef) {
	}

	ngOnChanges(): void {
		this._emptyViewRef?.destroy();

		if (this.ngForIfEmpty && this.ngForOf?.length === 0)
			this._emptyViewRef = this._viewContainerRef.createEmbeddedView(this.ngForIfEmpty);

		if (this.ngForIfEmptyOrNil && (isNil(this.ngForOf) || this.ngForOf.length === 0))
			this._emptyViewRef = this._viewContainerRef.createEmbeddedView(this.ngForIfEmptyOrNil);
	}

}
