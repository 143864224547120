import { isEmpty, isNil } from 'lodash-es';
import moment, { MomentInput } from 'moment';

import {
	OnChanges, OnInit, SimpleChanges, ChangeDetectionStrategy, Component, HostBinding, Input, ChangeDetectorRef,
	EventEmitter, Output
} from '@angular/core';

import { attrBoolValue } from '@bp/shared/utilities/core';
import { IErrorMessage } from '@bp/shared/models/core';

import { SLIDE } from '@bp/frontend/animations';
import { AppStorageService } from '@bp/frontend/services/storage';

@Component({
	selector: 'bp-alert',
	templateUrl: './alert.component.html',
	styleUrls: [ './alert.component.scss' ],
	animations: [ SLIDE ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class AlertComponent implements OnChanges, OnInit {

	@Input() type: 'accent' | 'danger' | 'info' | 'primary' | 'success' | 'warning' = 'primary';

	@Input()
	get errors(): IErrorMessage[] | null {
		return this._errors;
	}

	set errors(value: IErrorMessage[] | null | undefined) {
		this._errors = value ?? null;
	}

	private _errors!: IErrorMessage[] | null;

	@Input()
	get messages(): string[] | null {
		return this._messages;
	}

	set messages(value: string[] | null) {
		this._messages = value;
	}

	private _messages!: string[] | null;

	@Input() showType = true;

	@Input() closable = true;

	@Input() withBorder: boolean | '' = false;

	@Input() id: string | null = null;

	@Input() frequency: 'always' | 'daily' | 'weekly' = 'always';

	@Output() readonly afterClose = new EventEmitter<void>();

	@HostBinding('class.closing')
	closing = false;

	show = false;

	get isAlwaysShown(): boolean {
		return this.frequency === 'always';
	}

	@HostBinding('class.hidden')
	get hidden(): boolean {
		return isEmpty(this._errors) && isEmpty(this._messages);
	}

	private get _storageKey(): string {
		return this.id
			? `[alert].[${ this.type }].[${ this.frequency }]:${ this.id }`
			: JSON.stringify(this.errors ?? this.messages);
	}

	constructor(
		private readonly _appStorageService: AppStorageService,
		private readonly _cdr: ChangeDetectorRef,
	) { }

	ngOnChanges({ errors, messages }: Partial<SimpleChanges>): void {
		if (errors || messages) {
			const hasContent = !!this.errors || !!this.messages;

			this.show = hasContent
				? this.isAlwaysShown || this._isCountdownComplete()
				: false;

			this._clearContentIfHidden();
		}
	}

	ngOnInit(): void {
		this.withBorder = attrBoolValue(this.withBorder);
	}

	onSlideAnimationEnd(): void {
		this.closing = false;

		this._clearContentIfHidden();

		this._emitAfterCloseEventIfNotShowed();
	}

	private _clearContentIfHidden(): void {
		if (this.show)
			return;

		this._errors = this._messages = null;

		this._cdr.markForCheck();
	}

	close(): void {
		this.closing = true;

		this.show = false;

		if (!this.isAlwaysShown) {
			this._appStorageService.set(
				this._storageKey,
				moment().toISOString(),
			);
		}
	}

	private _emitAfterCloseEventIfNotShowed(): void {
		if (!this.show)
			this.afterClose.emit();
	}

	private _isCountdownComplete(): boolean {
		const shownDatetimeText: MomentInput = this._appStorageService.get(this._storageKey);

		if (isNil(shownDatetimeText))
			return true;

		const shownDatetime = moment(shownDatetimeText);
		const nextShowDatetime = shownDatetime
			.clone()
			.add(this.frequency === 'daily' ? 1 : 7, 'days');

		return moment()
			.isSameOrAfter(nextShowDatetime);
	}
}
