import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { getDisplayName } from '@bp/shared/utilities/core';

@Pipe({
	name: 'bpDisplayValue',
	standalone: false,
})
export class DisplayValuePipe implements PipeTransform {
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	transform(value?: any, displayValuePropertyName?: string): string {
		return getDisplayName(value, displayValuePropertyName);
	}
}
