import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Validators } from '@bp/shared/features/validation/models';

import { FormFieldControlComponent } from '@bp/frontend/components/core';

import { InputComponent } from '../input';

@Component({
	selector: 'bp-ip-input',
	templateUrl: './ip-input.component.html',
	styleUrls: [ './ip-input.component.scss' ],
	host: {
		'(focusout)': 'onTouched()',
	},
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: IpInputComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: IpInputComponent,
			multi: true,
		},
	],
	standalone: false,
})
export class IpInputComponent extends FormFieldControlComponent<string> {
	protected override _internalControl = new UntypedFormControl(
		'',
		Validators.ip,
	);

	@Input() override label = 'IP';

	@ViewChild(InputComponent) private readonly _input?: InputComponent;

	get valid() {
		return this._internalControl.valid;
	}

	/*
	 * MaskConfig: Partial<TextMaskConfig> = {
	 * 	mask: (value: string) => {
	 * 		const chars = value.split('');
	 */

	/*
	 * 		Const createIpGroup = (charsArr: string[], position: number) => [
	 * 			/[0-2]/,
	 * 			charsArr[position] === '2' ? /[0-5]/ : /[0-9]/,
	 * 			charsArr[position] === '2' ? /[0-5]/ : /[0-9]/
	 * 		];
	 */

	/*
	 * 		Const ipGroup1 = createIpGroup(chars, 1);
	 * 		const ipGroup2 = createIpGroup(chars, 5);
	 * 		const ipGroup3 = createIpGroup(chars, 9);
	 * 		const ipGroup4 = createIpGroup(chars, 13);
	 */

	/*
	 * 		Return [...ipGroup1, '.', ...ipGroup2, '.', ...ipGroup3, '.', ...ipGroup4];
	 * 	},
	 * 	includeMaskInValue: true
	 * };
	 */
	focus(): void {
		this._input?.focus();
	}

	protected override _onInternalControlValueChange(ip: string) {
		this._internalControl.valid && this.setValue(ip);
	}
}
