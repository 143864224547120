import { PipeTransform, Pipe, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
	name: 'bpTurnTrackerIssueNumbersIntoLinks',
	standalone: false,
})
export class TurnTrackerIssueNumbersIntoLinksPipe implements PipeTransform {

	private readonly __sanitizer = inject(DomSanitizer);

	transform(value: string): SafeHtml {
		return this.__sanitizer.bypassSecurityTrustHtml(
			this.__replaceTrackerIssueNumbersWithLink(value),
		);
	}

	private __replaceTrackerIssueNumbersWithLink(value: string): string {
		const issueNumbers = [ ...(value.match(/#?\w+-\d+/gu) ?? []) ];

		return issueNumbers.reduce((accumulator, issueNumber) => {
			const issueNumberWithoutHash = issueNumber.replace(/^#/u, '');
			const jiraIssueLink = `<a class="link" href="https://linear.app/bridgerpay/issue/${ issueNumberWithoutHash }" target="_blank" onclick="event.stopPropagation()">${ issueNumber }</a>`;

			return accumulator.replace(issueNumber, jiraIssueLink);
		}, value);
	}
}
