import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'bpDivide',
	standalone: false,
})
export class DividePipe implements PipeTransform {
	transform(argument1?: number | null, argument2?: number | null): number {
		return ((argument1 ?? 0) / (argument2 ?? 1));
	}
}
