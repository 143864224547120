import { chunk } from 'lodash-es';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'chunk',
	standalone: false,
})
export class ChunkPipe implements PipeTransform {
	transform<T>(array: T[] | null | undefined, size: number): T[][] {
		return array ? chunk(array, size) : [];
	}
}
