import { map } from 'lodash-es';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'toKeyValuePairs',
	standalone: false,
})
export class ToKeyValuePairsPipe implements PipeTransform {

	transform<T extends object>(v: T): { key: string; value: any }[] {
		return map(v, (value, key) => ({ value, key }));
	}

}
