import { Attribute, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { attrBoolValue, isEmpty } from '@bp/shared/utilities/core';

import { OutletLinkRelativeToTargetBaseDirective } from './outlet-link-relative-to-target-base.directive';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: ':not(a):not(area)[outletLinkRelativeTo],:not(a):not(area)[outletLinkRelativeToParent], :not(a):not(area)[outletLinkRelativeToRoot], :not(a):not(area)[outletLinkRelativeToRootFirstChild]',
	standalone: false,
})
export class OutletLinkRelativeToTargetDirective extends OutletLinkRelativeToTargetBaseDirective {

	constructor(
		router: Router,
		route: ActivatedRoute,
		@Attribute('tabindex') tabIndex: string | null,
		renderer: Renderer2,
		element: ElementRef,
	) {
		super(router, route);

		if (tabIndex === null)
			renderer.setAttribute(element.nativeElement, 'tabindex', '0');
	}

	@HostListener('click')
	onClick(): boolean {
		if (isEmpty(this._commands))
			return true;

		void this._router.navigateByUrl(this._urlTree, {
			skipLocationChange: attrBoolValue(this.skipLocationChange),
			replaceUrl: attrBoolValue(this.replaceUrlOnLocationHistory),
			state: this.state,
		});

		return true;
	}

}
