import { Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[bpElementRef]',
	exportAs: 'bpElementRef',
	standalone: false,
})
export class ElementRefDirective {

	nativeElement = this._host.nativeElement;

	constructor(private readonly _host: ElementRef<HTMLElement>) { }

}
