import { AfterContentInit, Directive, ElementRef, EventEmitter, inject, Output } from '@angular/core';

@Directive({
	selector: '[bpImagesLoadingHandler]',
	standalone: false,
})
export class ImagesLoadingHandlerDirective implements AfterContentInit {

	@Output('imagesLoaded') readonly imagesLoaded = new EventEmitter<void>();

	private readonly __elementRef = inject(ElementRef);

	async ngAfterContentInit(): Promise<void> {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
		const imgs: HTMLImageElement[] = [ ...this.__elementRef.nativeElement.querySelectorAll('img') ];

		const promises = [ ...imgs ].map(async img => new Promise((resolve, reject) => {
			img.addEventListener('load', () => void resolve(void 0));

			img.addEventListener('error', error => void reject(error));
		}));

		await Promise.allSettled(promises);

		this.imagesLoaded.emit();
	}

}
