import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'entries',
	standalone: false,
})
export class EntriesPipe implements PipeTransform {

	transform(object: object | null): [ string, any ][] {
		return object ? Object.entries(object) : [];
	}

}
