import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'bpInArray',
	standalone: false,
})
export class InArrayPipe<T> implements PipeTransform {
	transform(value: T, array: T[] | null): boolean {
		if (array === null)
			return false;

		return array.includes(value);
	}
}
