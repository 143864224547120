import { ChangeDetectionStrategy, Component, HostBinding, inject, Injector, isDevMode } from '@angular/core';

import { BaseDialogComponent } from '../base-dialog';

import { ActionConfirmDialogData, ACTION_CONFIRM_DIALOG_DESCRIPTION_COMPONENT_CONTEXT } from './action-confirm-dialog-data';

@Component({
	selector: 'bp-action-confirm-dialog',
	templateUrl: './action-confirm-dialog.component.html',
	styleUrls: [ './action-confirm-dialog.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class ActionConfirmDialogComponent<T extends ActionConfirmDialogData> extends BaseDialogComponent<T> {

	private readonly __injector = inject(Injector);

	@HostBinding('class.no-user-select')
	protected readonly _isNotDevMode = !isDevMode();

	protected readonly _actionConfirmDialogData = this._mergeDefaultOptions(this._dialogData);

	protected readonly _isAcknowledgeKind = this._actionConfirmDialogData.kind === 'acknowledge';

	protected readonly _isConfirmKind = this._actionConfirmDialogData.kind === 'confirm';

	protected readonly _isWarning = this._actionConfirmDialogData.color === 'warn';

	protected readonly _descriptionComponentInjector = this._createDescriptionComponentInjectorWithContext();

	protected _typedName = '';

	protected _canCloseWithTrueOnEnter(): boolean {
		return this._actionConfirmDialogData.requireTypedConfirm
			? this._typedName === (this._actionConfirmDialogData.typedConfirmText
				?? this._actionConfirmDialogData.name)
			: true;
	}

	protected _mergeDefaultOptions(data: T): T {
		return {
			...data,
			name: data.name?.trim(),
			kind: data.kind ?? 'confirm',
			title: data.title ?? 'Are you absolutely sure?',
		};
	}

	private _createDescriptionComponentInjectorWithContext(): Injector {
		return Injector.create({
			providers: [{
				provide: ACTION_CONFIRM_DIALOG_DESCRIPTION_COMPONENT_CONTEXT,
				useValue: this._actionConfirmDialogData.descriptionContext,
			}],
			parent: this.__injector,
		});
	}

}
