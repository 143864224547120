import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'bp-plus-minus-btn',
	templateUrl: './plus-minus-btn.component.html',
	styleUrls: [ './plus-minus-btn.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class PlusMinusBtnComponent {

	@Input()
	@HostBinding('class.minus')
	showMinus!: boolean;

}
