import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'bpSubtractPercent',
	standalone: false,
})
export class SubtractPercentPipe implements PipeTransform {
	transform(value: number, percent: number): number {
		return percent >= 1 ? value : value - (value * percent);
	}
}
