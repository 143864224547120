import { range } from 'lodash-es';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'range',
	standalone: false,
})
export class RangePipe implements PipeTransform {
	transform(start: number, end?: number, step?: number): number[] {
		return range(start, end, step);
	}
}
