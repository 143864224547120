import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { isPresent } from '@bp/shared/utilities/core';

@Pipe({
	name: 'isPresent',
	standalone: false,
})
export class IsPresentPipe implements PipeTransform {
	transform(value: unknown): boolean {
		return isPresent(value);
	}
}
