
import { differenceBy, isArray, isEmpty, isNil, isObject, isString, uniq, without } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { Memoize } from 'typescript-memoize';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
	ChangeDetectionStrategy, Component, ContentChild, Directive, ElementRef, Input, TemplateRef, TrackByFunction, ViewChild
} from '@angular/core';
import type { ValidationErrors, ValidatorFn } from '@angular/forms';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import type { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import type { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';

import type { IDescribable } from '@bp/shared/models/core/enum';
import {
	isPresent, bpQueueMicrotask, matchIgnoringCase, includesIgnoringCase, trackByIdentity
} from '@bp/shared/utilities/core';
import { Dictionary } from '@bp/shared/typings';

import { FADE, FADE_IN_LIST_STAGGERED } from '@bp/frontend/animations';
import { FormFieldControlComponent } from '@bp/frontend/components/core';
import type { OnChanges, SimpleChanges } from '@bp/frontend/models/core';

import { AutocompleteOptionDirective } from '../autocomplete';

export interface IChipControlItem extends IDescribable {
	[ prop: string ]: any;
}

const resetOption = 'reset';

type ResetOptionLiteral = typeof resetOption;

@Directive({
	selector: '[bpChip]',
	standalone: false,
})
export class ChipDirective {

	constructor(public tpl: TemplateRef<any>) { }

}

@Component({
	selector: 'bp-chips',
	templateUrl: './chips.component.html',
	styleUrls: [ './chips.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE, FADE_IN_LIST_STAGGERED ],
	host: {
		'(focusout)': 'onTouched()',
	},
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ChipsControlComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: ChipsControlComponent,
			multi: true,
		},
	],
	standalone: false,
})
export class ChipsControlComponent
	extends FormFieldControlComponent<IChipControlItem[] | null, IChipControlItem | string>
	implements OnChanges {

	@Input()
	get items(): IChipControlItem[] | undefined {
		return this._items;
	}

	set items(value: IChipControlItem[] | undefined) {
		this._items = value ?? [];
	}

	private _items: IChipControlItem[] = [];

	@Input() itemDisplayPropertyName?: string;

	@Input() resetOptionText?: string;

	@Input() filterListFn?: (item: any, search: string) => boolean;

	@Input() customAutocompleteOption?: AutocompleteOptionDirective;

	@Input() chipTpl?: TemplateRef<any>;

	protected _trackByFn = trackByIdentity;

	@Input()
	set trackByFn(trackByFunction: TrackByFunction<any> | undefined) {
		this._trackByFn = trackByFunction ?? trackByIdentity;
	}

	@ViewChild('autocomplete', { static: true }) autocomplete!: MatAutocomplete;

	@ViewChild('input', { static: true }) inputRef!: ElementRef;

	@ContentChild(ChipDirective)
	private readonly __customChip?: ChipDirective;

	protected get _customChipTpl(): TemplateRef<any> | undefined {
		return this.__customChip?.tpl ?? this.chipTpl;
	}

	@ContentChild(AutocompleteOptionDirective)
	private readonly __customAutocompleteOption?: AutocompleteOptionDirective;

	protected get _customAutocompleteOption(): AutocompleteOptionDirective | undefined {
		return this.__customAutocompleteOption ?? this.customAutocompleteOption;
	}

	get $input(): HTMLInputElement {
		return this.inputRef.nativeElement;
	}

	separatorKeysCodes: number[] = [ ENTER, COMMA ];

	override throttle = 0;

	filtered$ = new BehaviorSubject<IChipControlItem[]>([]);

	protected _resetOption = resetOption;

	override ngOnChanges(changes: SimpleChanges<this>) {
		super.ngOnChanges(changes);

		if (changes.items)
			this.__updateFilteredExcludingSelectedChips();
	}

	focus(): void {
		this.$input.focus();
	}

	// #region Implementation of the ControlValueAccessor interface
	override writeValue(value: IChipControlItem[] | null): void {
		this._assertValueFitsChipControlValueType(value);

		bpQueueMicrotask(() => {
			this._setIncomingValue(value);

			this.__updateFilteredExcludingSelectedChips();
		});
	}
	// #endregion Implementation of the ControlValueAccessor interface

	protected override _validator: ValidatorFn | null = (): ValidationErrors | null => null;

	protected override _onInternalControlValueChange(searchTermOrSelectedItem: IChipControlItem | string | typeof resetOption): void {
		if (isEmpty(this._items) || !isString(searchTermOrSelectedItem))
			return;

		this.__filterAvailableItems(searchTermOrSelectedItem);
	}

	add({ value }: MatChipInputEvent): void {
		if (!value)
			return;

		let foundChips = value.split(/,|\s/u)
			.map(searchTerm => searchTerm.trim())
			.filter(isPresent)
			.map(searchTerm => this._items.find(item => this.__matchItem(item, searchTerm)))
			.filter(isPresent);

		foundChips = this.__excludeSelectedChipsFromCollection(foundChips);

		this.select(...foundChips);

		this.__resetInput();
	}

	remove(item: IChipControlItem): void {
		this.setValue(without(this.__getSelectedChips(), item));

		this.__updateFilteredExcludingSelectedChips();
	}

	selected({ option: { value } }: MatAutocompleteSelectedEvent): void {
		this.select(value);
	}

	select(...value: IChipControlItem[]): void {
		this.setValue(uniq([ ...this.__getSelectedChips(), ...value ]));

		this.__updateFilteredExcludingSelectedChips();
	}

	override setValue(value: (IChipControlItem | ResetOptionLiteral)[] | null, options?: { emitChange: boolean }): void {
		this.__resetInput();

		const finalValue: IChipControlItem[] | null = isEmpty(value) || value?.includes(resetOption)
			? null
			: <IChipControlItem[]>value;

		super.setValue(finalValue, options);
	}

	private __filterAvailableItems(searchTerm: string): void {
		const trimmedSearchTerm = searchTerm.trim();

		const filtered = trimmedSearchTerm.length > 1
			? this._items.filter(item => this.__filterItem(item, trimmedSearchTerm))
			: this._items;

		this.filtered$.next(
			this.__excludeSelectedChipsFromCollection(filtered),
		);
	}

	private __resetInput(): void {
		this.$input.value = '';

		this._internalControl.setValue(null, { emitEvent: false });
	}

	private __getSelectedChips(): IChipControlItem[] {
		return this.value ?? [];
	}

	private __excludeSelectedChipsFromCollection(items: IChipControlItem[]): IChipControlItem[] {
		return differenceBy(
			items,
			this.__getSelectedChips(),
			(value: IChipControlItem, index: number) => this._trackByFn(index, value),
		);
	}

	private __updateFilteredExcludingSelectedChips(): void {
		this.filtered$.next(
			this.__excludeSelectedChipsFromCollection(this._items),
		);
	}

	@Memoize()
	private __getSearchableTerms(item: Dictionary<string> & object): string[] {
		const terms: string[] = [ `${ item }` ];

		if (isObject(item)) {
			if (this.itemDisplayPropertyName && this.itemDisplayPropertyName in item)
				terms.push(item[this.itemDisplayPropertyName]);

			if ('description' in item)
				terms.push(item['description']);

			if ('displayName' in item)
				terms.push(item['displayName']);

			if ('name' in item)
				terms.push(item['name']);
		}

		return terms;
	}

	private __matchItem(item: any, searchTerm: string): boolean {
		return this.__getSearchableTerms(item)
			.some(itemSearchTerm => matchIgnoringCase(itemSearchTerm, searchTerm));
	}

	private __filterItem(item: any, searchTerm: string): boolean {
		return this.filterListFn
			? this.filterListFn(item, searchTerm)
			: this.__getSearchableTerms(item)
				.some(itemSearchTerm => includesIgnoringCase(itemSearchTerm, searchTerm));
	}

	private __isIncorrectValueType(value: unknown): boolean {
		return !isNil(value) && !isArray(value);
	}

	private _assertValueFitsChipControlValueType(value: unknown): never | void {
		if (this.__isIncorrectValueType(value))
			throw new Error(`Incorrect incoming value ${ value } (not Array or Nil)`);
	}
}
