/* eslint-disable max-classes-per-file */
import { intersectionBy, isNil, isArray, isEmpty, isObject } from 'lodash-es';
import { BehaviorSubject, filter, merge, startWith } from 'rxjs';
import { map } from 'rxjs/operators';

import {
	ChangeDetectionStrategy, Component, Directive, EventEmitter, Input, TemplateRef, ContentChild,
	ViewChild, Output, OnInit
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacySelect as MatSelect, MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { attrBoolValue, bpQueueMicrotask, valueOf } from '@bp/shared/utilities/core';
import { IDescribable } from '@bp/shared/models/core';

import { FormFieldControlComponent } from '@bp/frontend/components/core';
import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';

@Directive({
	selector: '[bpSelectTrigger]',
	standalone: false,
})
export class SelectTriggerDirective {

	constructor(public tpl: TemplateRef<any>) { }

}

@Directive({
	selector: '[bpSelectOption]',
	standalone: false,
})
export class SelectOptionDirective {

	constructor(public tpl: TemplateRef<any>) { }

}

const resetOption = 'reset';

@Component({
	selector: 'bp-select-field[items]',
	standalone: false,
	templateUrl: './select-field.component.html',
	styleUrls: [ './select-field.component.scss' ],
	host: {
		'(focusout)': 'onTouched()',
	},
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: SelectComponent,
		multi: true,
	}],
})
export class SelectComponent<
	TSelectItem,
	TMultiple extends boolean | '' | null | undefined,
	TRequired extends boolean | '' | null | undefined,
	TControlValue extends (TMultiple extends '' | true ? TSelectItem[] : TSelectItem),
	TControlValueRequireness extends (TRequired extends '' | true ? never : null)
>
	extends FormFieldControlComponent<TControlValue | TControlValueRequireness> implements OnInit, OnChanges {

	private readonly __items$ = new BehaviorSubject<Set<TSelectItem> | TSelectItem[] | null | undefined>([]);

	@Input()
	get items(): Set<TSelectItem> | TSelectItem[] | null | undefined {
		return this.__items$.value;
	}

	set items(value: Set<TSelectItem> | TSelectItem[] | null | undefined) {
		this.__items$.next(value);
	}

	@Input() multiple?: TMultiple;

	@Input() override required?: TRequired;

	@Input() optionClass?: string;

	@Input() resetOptionText = 'None';

	@Input({ transform: coerceBooleanProperty })
	useItemValueOf = false;

	@Input() disableOptionCentering?: boolean | '';

	@Input() itemDisplayValuePropertyName?: string;

	@Input() panelClass = 'mat-select-panel';

	@Input() itemKind = 'item';

	@Input() optionTpl?: TemplateRef<any>;

	@Output() readonly selectionChange = new EventEmitter<MatSelectChange>();

	@ViewChild(MatSelect, { static: true })
	protected _select!: MatSelect;

	@ContentChild(TemplateRef)
	private readonly __customOptionTpl?: TemplateRef<any>;

	@ContentChild(SelectTriggerDirective)
	protected readonly _customTrigger?: SelectTriggerDirective;

	@ContentChild(SelectOptionDirective)
	private readonly __customOption?: SelectOptionDirective;

	protected get _customOptionTpl(): TemplateRef<any> | undefined {
		return this.__customOptionTpl ?? this.__customOption?.tpl ?? this.optionTpl;
	}

	protected _resetOption = resetOption;

	protected _onPanelOpenedChange$ = new BehaviorSubject(false);

	protected readonly _sortedItems$ = merge(
		this.__items$,
		this._onPanelOpenedChange$.pipe(filter(opened => !opened)),
		this._onWriteValue$.pipe(startWith(null)),
	).pipe(
		map(() => this.multiple
			? this.__sortItemsWithSelectedItemsAtTop()
			: this.items),
	);

	override ngOnInit(): void {
		super.ngOnInit();

		this.multiple = <TMultiple>attrBoolValue(this.multiple);

		this.disableOptionCentering = attrBoolValue(this.disableOptionCentering);

		this._cdr.markForCheck();
	}

	override ngOnChanges(changes: SimpleChanges<this>): void {
		super.ngOnChanges(changes);

		// handle the case when the items arrive after the value was set
		// so the value is kept in the serialized form
		if (changes.items && this.value)
			this.writeValue(this.value);
	}

	override writeValue(value: TControlValue | TControlValueRequireness): void {
		bpQueueMicrotask(() => void super.writeValue(
			this.__parseIncomingValue(value),
		));
	}

	focus(): void {
		this._select.focus();
	}

	protected override _onInternalControlValueChange(internalControlValue: unknown): void {
		const controlValue = this.__inferControlValueFromInternalControlValue(internalControlValue);

		if (controlValue === null)
			this._eraseInternalControlValue();

		this.setValue(controlValue);
	}

	protected _hasDescription(item: TSelectItem): item is IDescribable & TSelectItem {
		return isObject(item) && 'description' in item && item.description !== undefined;
	}

	private __parseIncomingValue(incomingValue: any): TControlValue | null {
		if (isNil(incomingValue))
			return null;

		if (this.multiple) {
			incomingValue = isArray(incomingValue) ? incomingValue : [ incomingValue ];

			if (this.items)
				incomingValue = intersectionBy([ ...this.items ], incomingValue, valueOf);
		} else {
			if (isArray(incomingValue))
				throw new Error('Single-select field cannot accept an array of values.');

			const parsedValue = <TControlValue | undefined>(this.items
				? [ ...this.items ].find(item => valueOf(item) === valueOf(incomingValue))
				: incomingValue
			);

			incomingValue = parsedValue
				? (this.useItemValueOf ? <TControlValue>valueOf(parsedValue) : parsedValue)
				: null;
		}

		return <TControlValue>incomingValue;
	}

	private __inferControlValueFromInternalControlValue(internalControlValue: unknown): TControlValue | TControlValueRequireness {
		const shouldReset = !this.required && internalControlValue === resetOption || isArray(internalControlValue) && internalControlValue.includes(resetOption);

		return <TControlValue | TControlValueRequireness> (shouldReset ? null : internalControlValue);
	}

	private __sortItemsWithSelectedItemsAtTop(): TSelectItem[] {
		const items = [ ...this.items! ];
		const selectedItems = <TSelectItem[]> (this._internalControl.value ?? []);

		return isEmpty(selectedItems)
			? items
			: items.sort((a, b) => selectedItems.includes(a) === selectedItems.includes(b)
				? items.indexOf(a) - items.indexOf(b)
				: selectedItems.indexOf(b) - selectedItems.indexOf(a));
	}

}
