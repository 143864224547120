import { isObject } from 'lodash-es';
import type { Observable } from 'rxjs';

import type { PipeTransform } from '@angular/core';
import { InjectionToken, Pipe } from '@angular/core';

import { TranslatePipeProviderService } from './translate-pipe-provider.service';

export const TRANSLATE_PIPE_PROVIDER = new InjectionToken<PipeTransform>('translate_pipe_provider');

@Pipe({
	name: 'bpTranslate',
	standalone: false,
})
export class TranslatePipe implements PipeTransform {

	constructor(private readonly _translatePipeProviderService: TranslatePipeProviderService) { }

	transform(query: unknown, ...args: any[]): Observable<string> {
		const queryString = isObject(query)
			? query.toString()
			: <string>query;

		return this._translatePipeProviderService.get(queryString, ...args);
	}
}
