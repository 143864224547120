import { take } from 'lodash-es';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'takeSafeCount',
	standalone: false,
})
export class TakeSafeCountPipe implements PipeTransform {
	transform<T>(array: T[] | null | undefined): T[] {
		return array ? take(array, 10) : [];
	}
}
