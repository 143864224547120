import { truncate } from 'lodash-es';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'bpTruncate',
	standalone: false,
})
export class BpTruncatePipe implements PipeTransform {

	transform(value: string, length: number, omission = ''): string {
		return truncate(value, { length, omission });
	}

}
