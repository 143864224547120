import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { TextMaskDirective } from '@bp/frontend/features/text-mask';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';
import { SharedFeaturesValidationModule } from '@bp/frontend/features/validation';
import { SharedPipesModule } from '@bp/frontend/pipes';
import { SharedFeaturesPluralizeModule } from '@bp/frontend/features/pluralize';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';

import { AutocompleteComponent, AutocompleteOptionDirective } from './autocomplete';
import { ButtonToggleComponent } from './button-toggle';
import { ChipDirective, ChipsControlComponent } from './chips';
import { CountrySelectorComponent } from './country-selector';
import { DateRangeComponent } from './date-range';
import { DateRangeShortcutsComponent } from './date-range-shortcuts';
import { DatePickerComponent } from './datepicker';
import {
	InputComponent, InputHintDirective, InputLabelDirective, InputPrefixDirective, InputSuffixDirective
} from './input';
import {
	InputChipsControlComponent, InputChipsHintDirective, InputChipsLabelDirective, InputChipsPrefixDirective
} from './input-chips';
import { IpInputComponent } from './ip-input';
import { PhoneInputComponent } from './phone-input';
import { SelectComponent, SelectOptionDirective, SelectTriggerDirective } from './select-field';
import { PasswordInputComponent } from './password-input';

const exposedComponents = [
	DateRangeComponent,
	DateRangeShortcutsComponent,
	InputComponent,
	InputHintDirective,
	InputLabelDirective,
	InputPrefixDirective,
	InputSuffixDirective,
	CountrySelectorComponent,
	IpInputComponent,
	DatePickerComponent,
	AutocompleteComponent,
	AutocompleteOptionDirective,
	SelectComponent,
	SelectOptionDirective,
	SelectTriggerDirective,
	ButtonToggleComponent,
	ChipsControlComponent,
	ChipDirective,
	InputChipsControlComponent,
	InputChipsHintDirective,
	InputChipsLabelDirective,
	InputChipsPrefixDirective,
	PhoneInputComponent,
	PasswordInputComponent,
];

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatIconModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatFormFieldModule,
		MatInputModule,
		MatChipsModule,
		MatDatepickerModule,
		MatProgressBarModule,
		MatSelectModule,
		ScrollingModule,

		SharedPipesModule,
		SharedDirectivesCoreModule,
		SharedComponentsCoreModule,
		SharedFeaturesTooltipModule,
		SharedFeaturesValidationModule,
		TextMaskDirective,
		SharedFeaturesPluralizeModule,
	],
	exports: exposedComponents,
	declarations: exposedComponents,
})
export class SharedComponentsControlsModule { }
