import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'bp-hint[message]',
	templateUrl: './hint.component.html',
	styleUrls: [ './hint.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class HintComponent {

	@Input() message!: string;

	@Input()
	size: 'lg' | 'md' | 'sm' = 'sm';

}
