import { sumBy } from 'lodash-es';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'sumBy',
	standalone: false,
})
export class SumByPipe implements PipeTransform {
	transform<T>(array: T[] | null, property: keyof T): number | null {
		return array && sumBy(array, <string> property);
	}
}
