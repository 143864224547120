import { Directive, Self } from '@angular/core';

import { TooltipDirective } from './tooltip.directive';

@Directive({
	selector: '[bpTooltipShownOnViewportOverflow]',
	standalone: false,
})
export class TooltipShownOnViewportOverflowDirective {

	constructor(@Self() public readonly tooltip: TooltipDirective) {
	}

}
