import { startCase } from 'lodash-es';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'titleCase',
	standalone: false,
})
export class TitleCasePipe implements PipeTransform {
	transform(value?: string | null): string {
		return startCase(value ?? '');
	}
}
